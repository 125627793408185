.button {
    position: relative;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 200px;
    -webkit-tap-highlight-color: transparent;
    transition: background-color ease 300ms, color ease 300ms;
    overflow: hidden;
    width: fit-content;
}

.button.no_padding {
    padding: 0;
}

.button.stretched {
    width: 100%;
}

.spinner_container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    position: relative;
    display: flex !important;
    justify-content: center;
}

.button .spinner,
.button svg {
    color: currentcolor;
    pointer-events: none;
}

.button:not(.disabled, .loading) {
    cursor: pointer;
}

.button.loading .content {
    visibility: hidden;
}

.text.loading {
    opacity: 0;
}

.nowrap {
    white-space: nowrap;
}

.content.content_with_icon {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    overflow: visible;
    align-items: center;
}

.icon_without_height {
    height: 0;
}

.size_xs {
    padding: 4px 8px;
}

.size_sm {
    padding: 5px 16px 6px;
}

.size_lg {
    padding: 16px 24px;
}

.variant_default {
    color: var(--colors-core-background-secondary) !important;
    background-color: var(--colors-control-default-bg) !important;
}

.variant_default.disabled {
    background-color: var(--colors-control-default-bgDisabled) !important;
    color: var(--colors-control-input-textDisabled) !important;
}

.variant_default:not(.disabled):hover {
    background-color: var(--colors-control-default-bgHover) !important;
}

.variant_default:not(.disabled):active {
    background-color: var(--colors-control-default-bgPress) !important;
}

.variant_primary {
    color: var(--colors-core-background-secondary) !important;
    background-color: var(--colors-control-primary-bg) !important;
}

.variant_primary.disabled {
    background-color: var(--colors-control-primary-bgDisabled) !important;
    color: var(--colors-control-input-textDisabled) !important;
}

.variant_primary:not(.disabled):hover {
    background-color: var(--colors-control-primary-bgHover) !important;
}

.variant_primary:not(.disabled):active {
    background-color: var(--colors-control-primary-bgPress) !important;
}

.variant_secondary {
    color: var(--colors-core-background-secondary) !important;
    background-color: var(--colors-control-secondary-bg) !important;
}

.variant_secondary.disabled {
    background-color: var(--colors-control-secondary-bgDisabled) !important;
    color: var(--colors-control-input-textDisabled) !important;
}

.variant_secondary:not(.disabled):hover {
    background-color: var(--colors-control-secondary-bgHover) !important;
}

.variant_secondary:not(.disabled):active {
    background-color: var(--colors-control-secondary-bgPress) !important;
}

.variant_tertiary {
    color: var(--colors-core-text-primary) !important;
    background-color: var(--colors-control-tertiary-bg) !important;
}

.variant_tertiary.disabled {
    background-color: var(--colors-control-tertiary-bgDisabled) !important;
    color: var(--colors-control-input-textDisabled) !important;
}

.variant_tertiary:not(.disabled):hover {
    background-color: var(--colors-control-tertiary-bgHover) !important;
}

.variant_tertiary:not(.disabled):active {
    background-color: var(--colors-control-tertiary-bgPress) !important;
}

.variant_text {
    color: var(--colors-control-secondary-bg) !important;
    background: transparent !important;
}

.variant_text.disabled {
    color: var(--colors-control-input-textDisabled) !important;
    background: transparent !important;
}

.variant_text:not(.disabled):hover {
    color: var(--colors-control-secondary-bgHover) !important;
    background: transparent !important;
}

.variant_text:not(.disabled):active {
    color: var(--colors-control-secondary-bgHover) !important;
    background: transparent !important;
}

.variant_textBg {
    color: var(--colors-core-text-secondary) !important;
    background: transparent !important;
}

.variant_textBg.disabled {
    color: var(--colors-control-input-textDisabled) !important;
    background: var(--colors-control-tertiary-bgDisabled) !important;
}

.variant_textBg:not(.disabled):hover {
    color: var(--colors-core-text-secondary) !important;
    background: var(--colors-control-tertiary-bgHover) !important;
}

.variant_textBg:not(.disabled):active {
    color: var(--colors-core-text-secondary) !important;
    background: var(--colors-control-tertiary-bgHover) !important;
}

.variant_flat {
    color: var(--colors-core-text-primary) !important;
    background-color: transparent !important;
}

.variant_flat.disabled {
    background-color: transparent !important;
    color: var(--colors-control-input-textDisabled) !important;
}

.variant_flat:not(.disabled):hover {
    background-color: transparent !important;
}

.variant_flat:not(.disabled):active {
    background-color: transparent !important;
}
