.logo {
    width: var(--logo-size-mobile-width);
    display: block;
    cursor: pointer;
}

.logo_skeleton {
    width: var(--logo-size-mobile-width);
    height: var(--logo-skeleton-size-mobile-height);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colors-control-primary-bg);
}

@media (min-width: 1024px) {
    .logo {
        width: var(--logo-size-desktop-width);
    }

    .logo_skeleton {
        width: var(--logo-size-desktop-width);
        height: var(--logo-skeleton-size-desktop-height);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
