.text {
    margin: 0;
    word-break: break-word;
}

.text p {
    margin: 0;
}

.italic {
    font-style: italic;
}

.nowrap {
    white-space: nowrap;
}

.select_none {
    user-select: none;
}

.typo_h1 {
    font-family: var(--typo-core-h1-fontFamily);
    font-weight: var(--typo-core-h1-fontWeight);
    font-size: var(--typo-core-h1-fontSize);
    line-height: var(--typo-core-h1-lineHeight);
    letter-spacing: var(--typo-core-h1-letterSpacing);
}

.typo_h2 {
    font-family: var(--typo-core-h2-fontFamily);
    font-weight: var(--typo-core-h2-fontWeight);
    font-size: var(--typo-core-h2-fontSize);
    line-height: var(--typo-core-h2-lineHeight);
    letter-spacing: var(--typo-core-h2-letterSpacing);
}

.typo_h3 {
    font-family: var(--typo-core-h3-fontFamily);
    font-weight: var(--typo-core-h3-fontWeight);
    font-size: var(--typo-core-h3-fontSize);
    line-height: var(--typo-core-h3-lineHeight);
    letter-spacing: var(--typo-core-h3-letterSpacing);
}

.typo_h4 {
    font-family: var(--typo-core-h4-fontFamily);
    font-weight: var(--typo-core-h4-fontWeight);
    font-size: var(--typo-core-h4-fontSize);
    line-height: var(--typo-core-h4-lineHeight);
    letter-spacing: var(--typo-core-h4-letterSpacing);
}

.typo_strong {
    font-family: var(--typo-core-strong-fontFamily);
    font-weight: var(--typo-core-strong-fontWeight);
    font-size: var(--typo-core-strong-fontSize);
    line-height: var(--typo-core-strong-lineHeight);
    letter-spacing: var(--typo-core-strong-letterSpacing);
}

.typo_body_lg_accent {
    font-family: var(--typo-core-bodyLgAccent-fontFamily);
    font-weight: var(--typo-core-bodyLgAccent-fontWeight);
    font-size: var(--typo-core-bodyLgAccent-fontSize);
    line-height: var(--typo-core-bodyLgAccent-lineHeight);
    letter-spacing: var(--typo-core-bodyLgAccent-letterSpacing);
}

.typo_body_lg {
    font-family: var(--typo-core-bodyLg-fontFamily);
    font-weight: var(--typo-core-bodyLg-fontWeight);
    font-size: var(--typo-core-bodyLg-fontSize);
    line-height: var(--typo-core-bodyLg-lineHeight);
    letter-spacing: var(--typo-core-bodyLg-letterSpacing);
}

.typo_body_md_accent {
    font-family: var(--typo-core-bodyMdAccent-fontFamily);
    font-weight: var(--typo-core-bodyMdAccent-fontWeight);
    font-size: var(--typo-core-bodyMdAccent-fontSize);
    line-height: var(--typo-core-bodyMdAccent-lineHeight);
    letter-spacing: var(--typo-core-bodyMdAccent-letterSpacing);
}

.typo_body_md {
    font-family: var(--typo-core-bodyMd-fontFamily);
    font-weight: var(--typo-core-bodyMd-fontWeight);
    font-size: var(--typo-core-bodyMd-fontSize);
    line-height: var(--typo-core-bodyMd-lineHeight);
    letter-spacing: var(--typo-core-bodyMd-letterSpacing);
}

.typo_paragraphL {
    font-family: var(--typo-core-paragraphL-fontFamily);
    font-weight: var(--typo-core-paragraphL-fontWeight);
    font-size: var(--typo-core-paragraphL-fontSize);
    line-height: var(--typo-core-paragraphL-lineHeight);
    letter-spacing: var(--typo-core-paragraphL-letterSpacing);
}

.typo_paragraphM {
    font-family: var(--typo-core-paragraphM-fontFamily);
    font-weight: var(--typo-core-paragraphM-fontWeight);
    font-size: var(--typo-core-paragraphM-fontSize);
    line-height: var(--typo-core-paragraphM-lineHeight);
    letter-spacing: var(--typo-core-paragraphM-letterSpacing);
}

.typo_label {
    font-family: var(--typo-core-label-fontFamily);
    font-weight: var(--typo-core-label-fontWeight);
    font-size: var(--typo-core-label-fontSize);
    line-height: var(--typo-core-label-lineHeight);
    letter-spacing: var(--typo-core-label-letterSpacing);
}

.typo_minor {
    font-family: var(--typo-core-minor-fontFamily);
    font-weight: var(--typo-core-minor-fontWeight);
    font-size: var(--typo-core-minor-fontSize);
    line-height: var(--typo-core-minor-lineHeight);
    letter-spacing: var(--typo-core-minor-letterSpacing);
}

.typo_micro {
    font-family: var(--typo-core-micro-fontFamily);
    font-weight: var(--typo-core-micro-fontWeight);
    font-size: var(--typo-core-micro-fontSize);
    line-height: var(--typo-core-micro-lineHeight);
    letter-spacing: var(--typo-core-micro-letterSpacing);
}

.color_inherit {
    color: inherit;
}

.color_primary {
    color: var(--colors-core-text-primary);
}

.color_secondary {
    color: var(--colors-core-text-secondary);
}

.color_tertiary {
    color: var(--colors-core-text-tertiary);
}

.color_brand {
    color: var(--colors-core-text-brand);
}

.color_success {
    color: var(--colors-core-text-success);
}

.color_warning {
    color: var(--colors-core-text-warning);
}

.color_alert {
    color: var(--colors-core-text-alert);
}

.color_neutral {
    color: var(--colors-core-text-neutral);
}
