.link {
    text-decoration: none;
}

@media (min-width: 1024px) {
    .button {
        text-align: left;
        overflow: visible;
    }
}
