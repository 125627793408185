@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-light.woff2') format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-medium-italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-bold-italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-black.woff2') format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: Family;
    src: url('./fonts/Family/family-black-italic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Fine.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Thin.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Light.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Regular.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-RegularItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Medium.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: LabilGrotesk;
    src: url('./fonts/LabilGrotesk/LabilGrotesk-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}
