/* stylelint-disable */

.story3_gdpr_banner_hidden {
    visibility: hidden;
}

#gdpr-consent-form {
    position: fixed;
    bottom: 32px;
    width: 100%;
    z-index: 1000;
}

.gdpr-container:not(.gdpr-container--fullscreen) {
    position: relative;
    margin: 0 auto;
    padding: 20px 20px 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    box-shadow: var(--shadows-modal);
    border-radius: 17px;
    background-color: var(--colors-core-background-secondary);
    font-family: var(--typo-core-bodyMd-fontFamily);
    font-weight: var(--typo-core-bodyMd-fontWeight);
    font-size: var(--typo-core-bodyMd-fontSize);
    line-height: var(--typo-core-bodyMd-lineHeight);
    letter-spacing: var(--typo-core-bodyMd-letterSpacing);
}

.gdpr-container strong {
    font-weight: inherit;
}

.gdpr-container a {
    text-decoration: none;
    color: var(--colors-core-text-primary);
}

.gdpr-container a svg {
    display: none;
}

.gdpr-close-button {
    position: absolute;
    right: 20px;
    background: transparent;
    border: none;
    color: var(--colors-core-text-tertiary);
    line-height: 24px;
    font-size: 20px;
}

.gdpr-close-button:hover {
    cursor: pointer;
}

.gdpr-cookies-form {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.gdpr-cookies-form__title,
.gdpr-settings-screen__title {
    width: 100%;
    margin: 0 0 16px;
    font-family: var(--typo-core-bodyLg-fontFamily);
    font-weight: var(--typo-core-bodyLg-fontWeight);
    font-size: var(--typo-core-bodyLg-fontSize);
    line-height: var(--typo-core-bodyLg-lineHeight);
}

.gdpr-cookies-form__description {
    margin: 0;
    color: var(--colors-core-text-secondary);
}

.gdpr-cookies-settings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 16px;
}

.gdpr-settings-screen {
    display: flex;
    flex-wrap: wrap;
}

.gdpr-settings-sidebar {
    width: 30%;
}

.gdpr-settings-tab:not(:last-child) {
    margin-bottom: 8px;
}

.gdpr-settings-tab__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 6px);
    padding: 0;
    min-height: 24px;
    line-height: 20px;
    border: none;
    text-align: left;
    background-color: transparent;
    cursor: pointer;
}

.gdpr-settings-tab__arrow {
    padding-right: 10px;
    height: 24px;
}

.gdpr-settings-tab__btn--active {
    background-color: var(--colors-control-tertiary-bg);
}

.gdpr-settings-screen__mobile-content,
.gdpr-settings-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;
    border-radius: 8px;
}

.gdpr-settings-screen__mobile-content {
    padding: 12px;
    background-color: var(--colors-control-tertiary-bgHover);
}

.gdpr-settings-main {
    background-color: var(--colors-core-background-secondary);
}

.gdpr-settings-screen__desktop-content {
    height: 100%;
    padding: 12px;
    background-color: var(--colors-control-tertiary-bgHover);
    border-radius: 8px;
}

.gdpr-settings-screen__desktop-content .gdpr-settings-content {
    height: 100%;
}

.gdpr-settings-content {
    height: 120px;
    overflow-y: auto;
    scrollbar-width: none;
}

.gdpr-settings-content::-webkit-scrollbar {
    display: none;
}

.gdpr-settings-footer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 8px;
    margin-top: 16px;
    width: 100%;
}

.gdpr-settings-footer__links-container {
    width: 25%;
}

.gdpr-settings-footer__buttons-container {
    display: flex;
    justify-content: space-between;
    column-gap: 80px;
    row-gap: 32px;
    flex-grow: 1;
}

.gdpr-settings-footer__buttons .gdpr-button--primary {
    width: 194px;
}

.gdpr-settings-footer__links-container *,
.gdpr-settings-footer__description {
    font-family: var(--typo-core-bodyMd-fontFamily);
    font-weight: var(--typo-core-bodyMd-fontWeight);
    font-size: var(--typo-core-bodyMd-fontSize);
    line-height: var(--typo-core-bodyMd-lineHeight);
    margin: 0;
}

.gdpr-settings-footer__links-container * {
    color: var(--colors-core-text-primary);
}

.gdpr-settings-footer__description {
    color: var(--colors-core-text-secondary);
}

.gdpr-checkbox {
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.gdpr-checkbox input {
    width: 22px;
    height: 22px;
    min-width: 22px;
    border: 1.5px solid var(--colors-core-background-selection);
    border-radius: 4px;
    margin: 0 8px 0 0;
    -webkit-appearance: none;
    background-color: var(--colors-core-background-secondary);
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}

.gdpr-checkbox input:checked {
    background-image: url('../../../assets/check-mark-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--colors-core-text-success);
    border-color: var(--colors-core-text-success);
}

.gdpr-checkbox input:disabled {
    background-image: url('../../../assets/check-mark.svg');
    background-color: var(--colors-control-input-bgHover);
    border-color: var(--colors-control-input-bgHover);
    cursor: not-allowed;
}

.gdpr-checkbox input:checked:disabled {
    background-color: var(--colors-control-input-bgHover);
}

.gdpr-checkbox__content {
    font-family: var(--typo-core-bodyMd-fontFamily);
    font-weight: var(--typo-core-bodyMd-fontWeight);
    font-size: var(--typo-core-bodyMd-fontSize);
    line-height: var(--typo-core-bodyMd-lineHeight);
}

.gdpr-checkbox__content strong {
    font-family: var(--typo-core-label-fontFamily);
    font-weight: var(--typo-core-label-fontWeight);
    font-size: var(--typo-core-label-fontSize);
    line-height: var(--typo-core-label-lineHeight);
}

.gdpr-tooltip {
    cursor: help;
}

.gdpr-tooltip svg {
    display: inline-block;
    height: 15px;
    width: 15px;
    transform: translateY(2px);
}

.gdpr-tooltip svg path {
    transform: scale(0.875);
}

.gdpr-tooltip__label {
    position: fixed;
    z-index: 1;
    padding: 12px 20px;
    background-color: var(--colors-core-background-secondary);
    box-shadow: var(--shadows-popover);
    border-radius: 12px;
}

.gdpr-cookies-form__mobile-description,
.gdpr-checkbox__border,
.gdpr-settings-screen__mobile-content {
    display: none;
}

.gdpr-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    align-self: flex-end;
}

.gdpr-button {
    padding: 16px 24px;
}

.gdpr-button--primary,
.gdpr-button--secondary {
    font-family: var(--typo-core-label-fontFamily);
    font-weight: var(--typo-core-label-fontWeight);
    font-size: var(--typo-core-label-fontSize);
    line-height: var(--typo-core-label-lineHeight);
    border: none;
    border-radius: 200px;
    cursor: pointer;
}

.gdpr-button--primary {
    white-space: nowrap;
    color: var(--colors-core-background-secondary);
    background-color: var(--colors-control-default-bg);
}

.gdpr-button--primary:hover {
    background-color: var(--colors-control-default-bgHover);
}

.gdpr-button--secondary {
    background-color: transparent;
}

.gdpr-give-consent--description {
    display: inline-block;
    margin-top: 4px;
    margin-left: 4px;
    color: var(--colors-core-text-secondary);
    font-family: var(--typo-core-minor-fontFamily);
    font-weight: var(--typo-core-minor-fontWeight);
    font-size: var(--typo-core-minor-fontSize);
    line-height: var(--typo-core-minor-lineHeight);
    letter-spacing: var(--typo-core-minor-letterSpacing);
}

@media (max-width: 599.98px) {
    .gdpr-container:not(.gdpr-container--fullscreen) {
        padding: 32px;
        border-radius: 20px 20px 0 0;
    }

    .gdpr-container.gdpr-container--fullscreen {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 32px;
        background-color: var(--colors-core-background-secondary);
    }

    .gdpr-close-button {
        right: 32px;
    }

    .gdpr-tooltip__mobile-label {
        align-self: center;
    }

    .gdpr-cookies-form {
        flex-direction: column;
        gap: 32px;
    }

    .gdpr-buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-self: initial;
    }

    .gdpr-button--primary,
    .gdpr-button--secondary {
        width: 100%;
    }

    .gdpr-settings-sidebar {
        width: 100%;
        border-radius: 8px;
    }

    .gdpr-settings-main {
        display: none;
    }

    .gdpr-settings-tab:not(:last-child) {
        margin: 0;
    }

    .gdpr-settings-tab__btn {
        width: 100%;
        padding: 12px;
        border-radius: 8px 8px 0 0;
    }

    .gdpr-settings-tab__btn--active {
        padding-top: 16px;
        padding-bottom: 0;
        background-color: var(--colors-control-tertiary-bgHover);
    }

    .gdpr-settings-tab__arrow {
        padding-right: 0;
        transform: rotate(90deg);
    }

    .gdpr-settings-screen__mobile-content {
        display: flex;
        width: 100%;
        border-radius: 0 0 8px 8px;
    }

    .gdpr-settings-footer {
        flex-direction: column;
        margin-top: 12px;
    }

    .gdpr-settings-footer__links-container {
        width: 100%;
    }

    .gdpr-settings-footer__buttons-container {
        flex-direction: column;
    }

    .gdpr-settings-footer__buttons .gdpr-button--primary {
        width: 100%;
    }
}

@media (max-width: 1023.98px) {
    #gdpr-consent-form {
        bottom: 0;
    }

    .gdpr-container.gdpr-container--fullscreen,
    .gdpr-container:not(.gdpr-container--fullscreen) {
        border-radius: 17px 17px 0 0;
    }
}

@media (max-height: 600px) {
    .gdpr-container {
        max-height: 100dvh;
        overflow-y: auto;
    }
}
