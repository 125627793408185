.container {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    vertical-align: top;
}

.label {
    margin-bottom: 8px;
}

.error {
    margin-top: 8px;
}

.label,
.error {
    height: 21px;
}
