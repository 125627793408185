.container {
    display: flex;
    align-items: center;
}

.spinner {
    color: inherit;
}

.icon {
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.icon_unsaved svg path {
    stroke: var(--colors-core-text-alert);
}

.text_unsaved {
    color: var(--colors-core-text-alert);
}
