.modal {
    display: flex;
    margin: auto;
    border-radius: 20px;
    max-height: var(--vh-fullscreen);
    background-color: var(--colors-core-background-secondary);
    box-shadow: var(--shadows-modal);
    overflow: hidden;
}

.pattern {
    min-width: 52px;
}

.container {
    width: 788px;
    padding-left: 28px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    flex-grow: 1;
}

.container > :first-child {
    padding-top: 24px;
}

.container > :last-child {
    padding-bottom: 24px;
}

.main {
    display: flex;
    flex-direction: column;
    padding-right: 48px;
}

@media (max-width: 599.98px) {
    .modal {
        width: 100%;
        margin: auto 0 0;
        border-radius: 20px 20px 0 0;
    }

    .pattern {
        min-width: 16px;
    }

    .container {
        padding: 0 20px;
    }

    .container > :first-child {
        padding-top: 32px;
    }

    .container > :last-child {
        padding-bottom: 32px;
    }

    .main {
        padding-right: 0;
    }
}
