.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: var(--colors-core-background-primary);
    color: var(--colors-core-text-primary);
    transition: background-color ease 300ms, color ease 300ms;
}

.icon:hover {
    background-color: var(--colors-control-secondary-bg);
    color: var(--colors-core-background-secondary);
}

.icon:active {
    background-color: var(--colors-control-secondary-bgPress);
    color: var(--colors-core-background-secondary);
}
