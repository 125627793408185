.modal {
    position: relative;
    margin: auto;
    border-radius: 30px;
    box-shadow: var(--shadows-modal);
    overflow: hidden;
}

.container {
    max-width: 840px;
    max-height: var(--vh-fullscreen);
    position: relative;
    background-color: var(--colors-core-background-secondary);
}

.groups {
    display: flex;
    overflow: hidden;
}

.group_1 {
    width: 420px;
    display: flex;
    flex-direction: column;
}

.group_2 {
    min-width: 420px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

.group_2_body {
    flex-grow: 3;
    height: 316px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 720px) {
    .modal {
        margin: auto 0 0;
        width: 100%;
        border-radius: 30px 30px 0 0;
    }

    .groups {
        width: 100%;
    }

    .group_1 {
        width: initial;
        flex-grow: 1;
    }

    .group_2 {
        min-width: 432px;
    }
}

@media (max-width: 600px) {
    .modal {
        border-radius: 20px 20px 0 0;
    }

    .groups {
        flex-direction: column;
    }

    .group_1 {
        flex-direction: row;
        width: initial;
        height: 158px;
    }

    .group_2 {
        min-width: initial;
    }

    .group_2 h1 {
        line-height: 48px;
    }
}

@media (max-width: 360px) {
    .group_1_small {
        flex-direction: row;
        width: initial;
        height: 42px;
    }

    .group_2_small h1 {
        line-height: 36px;
    }
}
