.container {
    display: flex;
    align-items: flex-end;
    height: 304px;
    padding: 48px;
    color: var(--colors-core-background-secondary);
}

.text {
    width: 310px;
}

@media (max-width: 600px) {
    .container {
        height: 216px;
        padding: 24px;
    }

    .text {
        width: initial;
    }
}
