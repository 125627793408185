* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font: var(--typo-fontFamily);
    background: var(--colors-core-background-secondary);
}

.textads {
    position: absolute;
    z-index: -999;
}

.story3_no_select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
}

.story3_no_select:focus {
    outline: none !important;
}

.story3_scrollable {
    overflow: auto;
    scrollbar-width: none;
}

.story3_scrollable::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
}

.story3_preload_images {
    width: 0;
    height: 0;
    display: inline;
    position: absolute;
    z-index: -999;
    background-image: url('assets/onboarding-1.svg'), url('assets/side-pattern-1.svg');
}

/* stylelint-disable */
#metaframeContainer {
    z-index: 1501;
}

@media (max-width: 768.98px) {
    #metaframeContainer {
        top: 5px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* stylelint-enable */

pre {
    font: inherit;
    margin: 0;
}

@media (max-width: 1023.98px) {
    body {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

#root {
    height: 100%;
    overflow: hidden;
}

.display_block {
    display: block;
}

.display_inline_block {
    display: inline-block;
}

.line_clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

#livechat {
    z-index: 0 !important;
}
