.container {
    flex-grow: 1;
    position: relative;
}

.preloader {
    visibility: hidden;
    position: absolute;
    z-index: -999;
}
