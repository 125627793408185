.container {
    margin: 36px auto 0 48px;
}

.text {
    width: 310px;
}

@media (max-width: 600px) {
    .container {
        margin: 24px 24px 48px;
    }

    .text {
        height: initial;
        width: initial;
    }
}
