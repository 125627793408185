.container {
    display: flex;
}

@media (max-width: 1023.98px) {
    .container {
        align-items: center;
        gap: 24px;
    }

    .title {
        width: 130px;
    }
}

@media (min-width: 1024px) {
    .container {
        flex-direction: column;
        gap: 16px;
    }
}
