.aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--colors-core-background-yellow);
    position: relative;
}

.onion {
    display: none;
}

.aside_content {
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.subtitle {
    margin-top: 16px;
    margin-bottom: 30px;
}

.legal_links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.create_button > div:first-child {
    justify-content: center;
    gap: 8px;
}

.babka_login {
    margin-top: 30px;
    height: 44px;
}

@media (max-width: 1024px) {
    .aside {
        display: block;
        background: unset;
    }

    .aside_content {
        height: 72px;
        flex-direction: column;
        padding: 20px 20px 4px;
    }
}

@media (min-width: 1024px) {
    .aside {
        min-width: 189px;
        width: 189px;
        padding: 56px 31px;
        box-sizing: content-box;
    }

    .aside_content {
        padding-bottom: 32px;
    }

    .onion {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
