.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}

.close {
    color: var(--colors-core-text-tertiary);
}
