.title {
    background-color: var(--colors-core-link-link);
}

@media (max-width: 720px) {
    .title {
        min-width: 432px;
    }
}

@media (max-width: 600px) {
    .title {
        min-width: initial;
    }
}
