.container:not(.transparent) {
    padding: 4px 8px;
    border-radius: 100px;
    width: fit-content;
}

.text {
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.text::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: var(--colors-core-text-primary);
}

.container_neutral:not(.transparent) {
    background: var(--colors-core-background-neutral);
}

.container_neutral > .text::before {
    background: var(--colors-core-text-neutral);
}

.container_neutral_changed:not(.transparent) {
    background: var(--colors-core-background-primary);
}

.container_neutral_changed > .text::before {
    background: var(--colors-core-text-neutral);
}

.container_warning:not(.transparent) {
    background: var(--colors-core-background-warning);
}

.container_warning > .text::before {
    background: var(--colors-core-text-warning);
}

.container_alert:not(.transparent) {
    background: var(--colors-core-background-alert);
}

.container_alert > .text::before {
    background: var(--colors-core-text-alert);
}

.container_success:not(.transparent) {
    background: var(--colors-core-background-success);
}

.container_success > .text::before {
    background: var(--colors-core-text-success);
}
