.spinner {
    display: block;
}

.default {
    color: var(--colors-control-primary-bg);
}

.spinner::after {
    content: ' ';
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid;
    border-color: currentcolor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
