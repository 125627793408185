.container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 24px;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.picture {
    margin-bottom: 42px;
    max-width: 602px;
    width: 100%;
    height: auto;
}

.text {
    text-align: center;
    max-width: 742px;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
}

@media (max-width: 1200px) {
    .picture {
        max-width: 504px;
        margin-bottom: 24px;
    }

    .text {
        font-size: 20px;
        line-height: 26px !important;
        max-width: 530px;
    }
}

@media (max-width: 576px), (orientation: landscape) and (max-height: 480px) {
    .picture {
        max-width: 280px;
        margin-bottom: 24px;
    }

    .buttons {
        align-items: stretch;
        width: 100%;
    }

    .text {
        font-size: 16px;
        line-height: 20px !important;
        max-width: 300px;
    }
}

@media (orientation: landscape) and (max-height: 480px) {
    .picture {
        margin-bottom: 16px;
    }

    .buttons {
        flex-direction: row;
        margin-top: 16px;
    }
}
