.pattern {
    background-position: top;
}

.primary {
    background-image: var(--sidePattern-backGroundImage-desktop);
    background-size: var(--sidePattern-backgroundSize-desktop);
    background-position: center;
}

.secondary {
    background-image: var(--sidePattern-backGroundImage-mobile);
    background-size: var(--sidePattern-backgroundSize-mobile);
}
