.container {
    position: fixed;
    top: 0;
    left: 303px;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 2000;
}

.list {
    position: absolute;
    top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 600px;
}

.notification {
    padding: 12px 16px 12px 12px;
    background-color: var(--colors-core-background-secondary);
    box-shadow: var(--shadows-uber);
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: center;
}

.content {
    flex-grow: 1;
}

.cross {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.animation_enter {
    opacity: 1;
}

.animation_exit {
    opacity: 0;
}

.animation_enter_active,
.animation_exit_active {
    animation-name: slide;
    animation-timing-function: ease-in;
}

.animation_exit_active {
    animation-direction: reverse;
}

@keyframes slide {
    from {
        opacity: 0;
        transform: translateY(-16px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1023.98px) {
    .container {
        left: 16px;
    }

    .list {
        top: 72px;
        max-width: calc(100% - 56px);
    }
}
