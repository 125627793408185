.anchor {
    text-decoration: none;
}

.color_inherit {
    color: inherit;
}

.color_link {
    color: var(--colors-core-link-link);
}
