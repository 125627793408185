.container {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 24px;
}

.container .button {
    padding: 4px;
}

.container:hover .button {
    background-color: var(--colors-control-default-bgHover) !important;
}

@media (max-width: 600px) {
    .container {
        padding-top: 16px;
        padding-left: 16px;
    }
}
