.clickable {
    cursor: pointer;
}

.clickable:hover .button {
    background-color: var(--colors-control-default-bgHover) !important;
}

.text {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    color: var(--colors-core-background-secondary);
}
