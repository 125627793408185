.modal {
    margin: auto;
    display: flex;
    border-radius: 20px;
    max-height: var(--vh-fullscreen);
    background-color: var(--colors-core-background-secondary);
    box-shadow: var(--shadows-modal);
    overflow: hidden;
}

.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
}

.container > :first-child {
    padding-top: 32px;
}

.container > :last-child {
    padding-bottom: 32px;
}

@media (max-width: 599.98px) {
    .modal {
        width: 100%;
        margin: auto 0 0;
        border-radius: 20px 20px 0 0;
    }

    .container {
        padding: 0 20px;
    }
}
