.container {
    margin: 0 auto 48px 48px;
    width: 310px;
}

@media (max-width: 600px) {
    .container {
        margin: 0 24px 24px;
        width: initial;
    }
}
