.preloader {
    background-image: url('../../../../assets/onboarding-1.svg');
}

.headerBlock {
    height: 120px;
    background-color: var(--colors-core-background-pink);
}

@media (max-width: 600px) {
    .modal :global(.story3_scrollable) > div {
        > div:first-child {
            min-height: 186px;
        }

        > div:last-child {
            > div:first-child {
                max-height: 186px;
            }

            > div:last-child {
                gap: 33px;
                padding: 24px;
                height: initial;

                div {
                    margin: 0;
                }
            }
        }
    }

    .headerBlock {
        width: 32%;
        height: initial;
    }
}
