.container {
    flex-grow: 1;
    position: relative;
    background-color: var(--colors-core-link-linkHover);
}

.img {
    position: absolute;
    top: -46px;
    right: -40px;
    height: 387px;
    width: 388px;
    background-image: url('../../../../../assets/onboarding-1.svg');
    background-size: cover;
}

@media (max-width: 720px) {
    .img {
        right: -22px;
    }
}

@media (max-width: 600px) {
    .container {
        position: static;
    }

    .img {
        top: 26px;
        right: calc(50% - 105px);
        bottom: 0;
        width: 210px;
        height: 210px;
    }
}

@media (max-width: 360px) {
    .img {
        display: none;
    }
}
