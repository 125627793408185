.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    color: var(--colors-core-background-secondary);
}

.statusText {
    color: var(--colors-core-background-secondary);
}
