.container {
    height: 100%;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
}

.side_pattern {
    flex-shrink: 0;
    width: var(--withSideBar-sidePattern-size-desktop-width);
    background-color: var(--colors-core-background-yellow);
}

.main {
    flex: 1 1 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    position: relative;
    display: flex;
}

.content {
    flex: 1 1 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    position: relative;
}

.content::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
}

.mobile_navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 4px 24px rgb(0 0 0 / 10%);
}

.desktop_navbar {
    width: 20%;
    min-width: 375px;
}

.create_story {
    position: fixed !important;
    bottom: 84px;
    right: 16px;
}

.onboardingTooltip:global(.MuiTooltip-popper) {
    :global(.MuiTooltip-tooltip) {
        transform: translateY(-28px) !important;
        max-width: 250px;
    }
}

@media (max-width: 1201px) {
    .desktop_navbar {
        min-width: 189px;
    }
}

@media (max-width: 1024px) {
    .side_pattern {
        width: var(--withSideBar-sidePattern-size-mobile-width);
    }

    .main {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 1024px) {
    .container {
        height: 100vh;
    }

    .create_story {
        visibility: hidden;
    }

    .onboardingTooltip {
        display: none;
    }
}
